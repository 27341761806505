
const states_obj = {
  "AN" :	"Andaman & Nicobar Islands",
  "AP" : 	"Andhra Pradesh",
  "AS" : 	"Assam",
  "BR" : 	"Bihar",
  "CG" :	"Chandigarh",
  "CH" : 	"Chhattisgarh",
  "DD" :	"Daman & Diu",
  "DL" :	"Delhi",
  "GJ" : 	"Gujarat",
  "HR" : 	"Haryana",
  "HP" : 	"Himachal Pradesh",
  "JK" :	"Jammu & Kashmir",
  "JH" : 	"Jharkhand",
  "KA" : 	"Karnataka",
  "KL" : 	"Kerala",
  "MP" : 	"Madhya Pradesh",
  "MH" : 	"Maharashtra",
  "MN" :	"Manipur",
  "NL" :	"Nagaland",
  "OR" : 	"Odisha",
  "PY" :	"Puducherry",
  "PB" : 	"Punjab",
  "RJ" : 	"Rajasthan",
  "TN" : 	"Tamil Nadu",
  "TS" : 	"Telangana",
  "TR" :	"Tripura",
  "UP" : 	"Uttar Pradesh",
  "UK" : 	"Uttarakhand",
  "WB" : 	"West Bengal"
};

const sub_User_keys = {
  'Admin': ['RDGM', 'RDGMName', 'RDGMEmail'],
  'RDGM': ['TDGM', 'TDGMName', 'TDGMEmail'],
  'TDGM': ['MIE', 'MIEName', 'MIEEmail'],
  'MIE': ['MDO', 'MDOName', 'MDOEmail'],
  'MDO': ['MDO', 'MDOName', 'MDOEmail'],
}

var User_Data;

var User_Role;
var User_States_arr;
var User_Territories;
var User_Email;

var Users_LinkedData;
get_UserData();



function get_UserData() {
  var data;
  $.ajax({
    type: 'GET',
    url: 'https://pioneer.redmatter.tech/pioneer/web/cp_users.php',
    // contentType: "application/json",
    dataType: 'json',
    async: false,
    success: function(result) {
      User_Data = result.users;
      // console.log(JSON.stringify(User_Data));
      
      check_User();
    },
    error: function(result) {
      console.log('Error in fetching User Data');
    }
  });
}

function check_User() {
  var temp;
  for (var i = 0; i < User_Data.length; ++i) {
    if (statesUrles.toLowerCase() === (User_Data[i].email).toLowerCase()) {
      temp = User_Data[i];
    }
  }
  User_Data = temp;

  console.log("UserData: " + JSON.stringify(User_Data));

  User_Role = User_Data.role;
  User_Email = User_Data.email;
  
  localStorage.setItem('User_Role', User_Role);
  localStorage.setItem('User_Email', User_Email);

  if (User_Role == 'Admin') {
    $('.sub_user_filter_div').addClass('d-none');
    $('.clear_search_div').addClass('offset-md-3');
  }
  $('.sub_user_filter_div .user_role').text('('+ sub_User_keys[User_Role][0] +')');
  

  get_MieMdo_data(User_Role, User_Email);
  
}

function get_MieMdo_data(User_Role, User_Email) {
  // var services_domain = 'http://localhost:3000';
  const services_domain = 'https://7x6xwprtec.execute-api.ap-south-1.amazonaws.com';
  
  var req = {
    role: User_Role,
    email: User_Email
  };
  
  var data;
  $.ajax({
    type: 'POST',
    url: services_domain + '/dev/api/commonapis/mie-mdo',
    contentType: "application/json",
    data: JSON.stringify(req),
    dataType: 'json',
    async: false,
    success: function(result) {
      if (result.status) {
        Users_LinkedData = result.data;
        doNext();
      }      
    },
    error: function(result) {
      console.log('Error in MIE-MDO Data');
    }
  });
}

function fill_DrpDwn_States(arr) {
  var html = '';
  
  $.each(arr, function(i, v) {
    html += '<option value="'+ v +'">'+ v +'</option>';
  });

  $('.select_states').html(html);
}

function get_subUsers_List(filtered_States_arr, role = User_Role) {
  const user_key = sub_User_keys[role][1];
  const email_key = sub_User_keys[role][2];

  var arr = $.grep(Users_LinkedData, function(data) {
            if ($.inArray(data.State, filtered_States_arr) !== -1) {
              return true;
            }
            return false;
          });

  var sub_users = {};
  $.each(arr, function(i, v) {
      sub_users[v[email_key]] = v[user_key];
  });

  return sub_users;
}

function get_Territories_List(email, role = User_Role) {

  var arr = $.grep(Users_LinkedData, function(data) {
            if ($.inArray(data.State, User_States_arr) !== -1) {
              if (isValidString(email)) {
                return (data[sub_User_keys[role][2]] == email);
              }
              return true;
            }
            return false;
          });

  var t_arr = [];
  $.each(arr, function(i, v) {
    if ($.inArray(v['MDOTerritory'], t_arr) == -1) {
      t_arr.push(v['MDOTerritory']);
    }
  });

  return t_arr;
}

function doNext() {

  User_States_arr = [];
  if (User_Data.states == 'ALL') {
    $.each(states_obj, function(i,v) {
      User_States_arr.push(v);
    });
  } else {
    // User_States_arr = User_Data.states;

    $.each(Users_LinkedData, function(i, v) {
      if ($.inArray(v.State, User_States_arr) == -1) {
        User_States_arr.push(v.State);
      }
    });
  }

  fill_DrpDwn_States(User_States_arr);

  User_Territories = get_Territories_List();
  
}



/* Helper functions */

function isValidString(val) {
  if (val == '' || typeof val == 'undefined' || val == null) {
    return false;
  } else {
    return true;
  }
}


module.exports = {
  User_Role: User_Role,
  User_States_arr: User_States_arr,
  User_Territories: User_Territories,
  isValidString: isValidString,
  get_subUsers_List: get_subUsers_List,
  get_Territories_List: get_Territories_List
}



  