
const { User_Role, User_States_arr, User_Territories, isValidString, get_subUsers_List, get_Territories_List } = require('./user_data.js');


// const non_Admin_arr = ['RDGM', 'TDGM', 'MIE', 'MDO'];
const non_Admin_arr = ['TDGM', 'MIE', 'MDO'];

import ChartsEmbedSDK from "@mongodb-js/charts-embed-dom";
import "@alenaksu/json-viewer";

const sdk = new ChartsEmbedSDK({
  baseUrl: "https://charts.mongodb.com/charts-project-0-quszd", // Optional: ~REPLACE~ with the Base URL from your Embed Chart dialog
});

var user_filter = {};
if (User_Role != "Admin") {
  user_filter['State'] = {"$in": User_States_arr};
}
if(($.inArray(User_Role, non_Admin_arr) != -1)) {
    /* For Non-Admin Users, Territories should be there */
    user_filter['territory_name'] = {"$in": convert_array_to_RegexArray(User_Territories)};
}

var chart_refresh = true;
var chart_maxAge = 60 * 15;


var all_chart_ids = {
  p_chart1: {
    id: "6226e595-5f2f-4ace-8af1-4374b1b94d9c",
    height: "400px",
  },
  p_chart2: {
    id: "6226e595-5f2f-47b7-82bd-4374b1b94d9e",
    height: "500px",
  },
  p_chart3: {
    id: "6226e595-5f2f-4bc6-8fa9-4374b1b94da0",
    height: "300px",
  },
  p_chart10: {
    id: "6226e595-5f2f-4e75-86ce-4374b1b94daa",
    height: "300px",
  },
  p_chart12: {
    id: "62c573b7-6d77-4efa-839c-0fc61c90429f",
    height: "300px",
  },
  p_chart13: {
    id: "62c576ef-d694-4539-8fe9-a13c4a53a562",
    height: "500px",
  },
  p_chart14: {
    id: "657c9c24-dd7c-4513-abec-8b31c2935396",
    height: "500px",
  },
  // p_chartX: {
  //   id: "62c574a0-7e6a-4792-8452-26c53d952726",
  //   height: "500px",
  // },
  p_chart11: {
    id: "6226e595-5f2f-4426-83b1-4374b1b94dac",
    height: "500px",
  },
  p_chart4: {
    id: "62301d92-56bd-47b8-87ff-d8c93ee9eac1",
    height: "500px",
  },
  p_chart5: {
    id: "6226e595-5f2f-4a48-88d2-4374b1b94da2",
    height: "500px",
  },
  p_chart6: {
    id: "6226e595-5f2f-4fc9-8b30-4374b1b94da8",
    height: "500px",
  },
  p_chart7: {
    id: "6226e595-5f2f-4526-8ccb-4374b1b94da6",
    height: "500px",
  },
  p_chart8: {
    id: "6226e595-5f2f-42bb-8d7e-4374b1b94dae",
    height: "500px",
  },
  p_chart9: {
    id: "6226e595-5f2f-4e4f-86de-4374b1b94db1",
    height: "500px",
  },
  p_chart15: {
    id: "62e8c1b6-89cf-460b-833a-6cc523c9bb97",
    height: "400px",
  },
  p_chart16: {
    id: "62e8c1d2-409c-499a-81a1-a95bddaaa6aa",
    height: "400px",
  },

  r_chart1: {
    id: "621c6ca8-2bc2-4f2b-8bdb-35cce968276f",
    height: "400px",
  },
  r_chart2: {
    id: "621c6d12-283b-42c0-8c94-b1c235bb138c",
    height: "500px",
  },
  r_chart3: {
    id: "621c6d83-abd4-4fce-888a-7244b2678376",
    height: "300px",
  },
  r_chart4: {
    id: "621c716b-7ea9-4de1-8255-bbffcf853c7e",
    height: "300px",
  },
  r_chart5: {
    id: "62c54353-6bc4-420b-844e-15b909d77a03",
    height: "300px",
  },
  r_chart6: {
    id: "62c5600f-94d4-4888-876f-b65b2bd2de88",
    height: "400px",
  },
  r_chart7: {
    id: "621c723f-cb14-4e46-8179-6229bf24e5e8",
    height: "400px",
  },
  r_chart8: {
    id: "62301a59-73ca-403e-8c9e-34249825c97f",
    height: "400px",
  },
  r_chart9: {
    id: "621c6ed5-2bc2-40db-8314-35cce9694e1a",
    height: "400px",
  },
  r_chart10: {
    id: "621c70f9-905d-4441-86c1-4843ae2165e3",
    height: "400px",
  },
  r_chart11: {
    id: "621c7047-283b-4ce1-841e-b1c235bc4a8b",
    height: "400px",
  },
  r_chart12: {
    id: "621c72a6-2bc2-4f57-86c8-35cce96b052a",
    height: "400px",
  },
  r_chart13: {
    id: "6226e227-bf9b-48e0-84bc-f7a4c8187dea",
    height: "500px",
  },
  r_chart14: {
    id: "9a148dff-f46d-4f3b-b5b6-f12a40138fcb",
    height: "400px",
  },
  r_chart15: {
    id: "62e8be33-8807-475c-8b66-b03030eadb2c",
    height: "400px",
  },
  r_chart16: {
    id: "62e8bed4-12d2-4c8d-801c-41ca7c9bbfe6",
    height: "400px",
  },
};

function createChartObj(chart) {
  return sdk.createChart({
    chartId: all_chart_ids[chart]['id'], // Optional: ~REPLACE~ with the Chart ID from your Embed Chart dialog
    height: all_chart_ids[chart]['height'],
    filter: user_filter,
    autoRefresh: chart_refresh,
    maxDataAge: chart_maxAge
  });
}

const chart1 = createChartObj('p_chart1');
const chart2 = createChartObj('p_chart2');
const chart3 = createChartObj('p_chart3');
const chart4 = createChartObj('p_chart4');
const chart5 = createChartObj('p_chart5');
const chart6 = createChartObj('p_chart6');
const chart7 = createChartObj('p_chart7');
const chart8 = createChartObj('p_chart8');
const chart9 = createChartObj('p_chart9');
const chart10 = createChartObj('p_chart10');
const chart11 = createChartObj('p_chart11');
const chart12 = createChartObj('p_chart12');
const chart13 = createChartObj('p_chart13');
const chart14 = createChartObj('p_chart14');
const chart15 = createChartObj('p_chart15');
const chart16 = createChartObj('p_chart16');


var charts_rendered_cnt = 0;
function on_chart_render_complete() {
  charts_rendered_cnt++;
  // console.log(charts_rendered_cnt);
}

async function renderCharts_portfolio() {
  chart1.render(document.getElementById("chart")).then(on_chart_render_complete());
  chart2.render(document.getElementById("chartMap")).then(on_chart_render_complete());
  chart3.render(document.getElementById("chartProduct")).then(on_chart_render_complete());
  chart4.render(document.getElementById("chartState")).then(on_chart_render_complete());
  chart5.render(document.getElementById("chartAP")).then(on_chart_render_complete());
  chart6.render(document.getElementById("chartDC")).then(on_chart_render_complete());
  chart7.render(document.getElementById("chartCS")).then(on_chart_render_complete());
  chart8.render(document.getElementById("chartTP")).then(on_chart_render_complete());
  chart9.render(document.getElementById("portfolio_tbl")).then(on_chart_render_complete());
  chart10.render(document.getElementById("chartGrower")).then(on_chart_render_complete());
  chart11.render(document.getElementById("chartSeason")).then(on_chart_render_complete());
  chart12.render(document.getElementById("chart_FarmerType_portfolio")).then(on_chart_render_complete());
  chart13.render(document.getElementById("chart_FarmerType2_portfolio")).then(on_chart_render_complete());
  chart14.render(document.getElementById("chart_demosbyterritory_portfolio")).then(on_chart_render_complete());
  // chartX.render(document.getElementById("chart_democount_portfolio")).then(on_chart_render_complete());
  chart15.render(document.getElementById("p_field_visits")).then(on_chart_render_complete());
  chart16.render(document.getElementById("p_harvest_visits")).then(on_chart_render_complete());

  /* Filtering charts in the beginning itself */
  // setTimeout(function() {
  //   render_filteredCharts_portfolio(true);
  // }, 1000);
  
  document.getElementById("mySelect").addEventListener("change", async (e) => {
    $('#territory_name').val('');
    render_filteredCharts_portfolio();
  });
  document.getElementById("territory_name").addEventListener("change", async (e) => {
    render_filteredCharts_portfolio();
  });
  document.getElementById("createdDate").addEventListener("change", async (e) => {
    render_filteredCharts_portfolio();
  });
  document.getElementById("endDate").addEventListener("change", async (e) => {
    render_filteredCharts_portfolio();
  });
  document.getElementById("product_name").addEventListener("change", async (e) => {
    render_filteredCharts_portfolio();
  });
  document.getElementById("crop_name").addEventListener("change", async (e) => {
    render_filteredCharts_portfolio();
  });
}

function render_filteredCharts_portfolio(all) {
  chart1.setFilter(getFiltersData_PortfolioTab(all));
  chart2.setFilter(getFiltersData_PortfolioTab(all));
  chart3.setFilter(getFiltersData_PortfolioTab(all));
  chart4.setFilter(getFiltersData_PortfolioTab(all));
  chart5.setFilter(getFiltersData_PortfolioTab(all));
  chart6.setFilter(getFiltersData_PortfolioTab(all));
  chart7.setFilter(getFiltersData_PortfolioTab(all));
  chart8.setFilter(getFiltersData_PortfolioTab(all));
  chart9.setFilter(getFiltersData_PortfolioTab(all));
  chart10.setFilter(getFiltersData_PortfolioTab(all));
  chart11.setFilter(getFiltersData_PortfolioTab(all));
  chart12.setFilter(getFiltersData_PortfolioTab(all));
  chart13.setFilter(getFiltersData_PortfolioTab(all));
  chart14.setFilter(getFiltersData_PortfolioTab(all));
  chart15.setFilter(getFiltersData_PortfolioTab(all));
  chart16.setFilter(getFiltersData_PortfolioTab(all));
}




/* --- REGULAR DEMO --- */
const chartas = createChartObj('r_chart1');
const charts1 = createChartObj('r_chart2');
const chartP1 = createChartObj('r_chart3');
const chartRGL1 = createChartObj('r_chart4');
const chartFarmerType = createChartObj('r_chart5');
const chartFarmerType2 = createChartObj('r_chart6');
const chartSeason1 = createChartObj('r_chart7');
const chartS1 = createChartObj('r_chart8');
const chartAP1 = createChartObj('r_chart9');
const chartDC1 = createChartObj('r_chart10');
const chartCS1 = createChartObj('r_chart11');
const chartTP1 = createChartObj('r_chart12');
const chart_reg_tble = createChartObj('r_chart13');
const chart_demosbyterritory_regular = createChartObj('r_chart14');
const r_chart15 = createChartObj('r_chart15');
const r_chart16 = createChartObj('r_chart16');


async function renderCharts_regular() {
  chartas.render(document.getElementById("chartRegCount")).then(on_chart_render_complete());
  charts1.render(document.getElementById("chartMap1")).then(on_chart_render_complete());
  chartP1.render(document.getElementById("chartProduct1")).then(on_chart_render_complete());
  chartRGL1.render(document.getElementById("chartRGL1")).then(on_chart_render_complete());
  chartFarmerType.render(document.getElementById("chartFarmerType")).then(on_chart_render_complete());
  chartFarmerType2.render(document.getElementById("chartFarmerType2")).then(on_chart_render_complete());
  chartSeason1.render(document.getElementById("chartSeason1")).then(on_chart_render_complete());
  chartS1.render(document.getElementById("chartState1")).then(on_chart_render_complete());
  chartAP1.render(document.getElementById("chartAP1")).then(on_chart_render_complete());
  chartDC1.render(document.getElementById("chartDC1")).then(on_chart_render_complete());
  chartCS1.render(document.getElementById("chartCS1")).then(on_chart_render_complete());
  chartTP1.render(document.getElementById("chartTP1")).then(on_chart_render_complete());
  chart_reg_tble.render(document.getElementById("regular_tbl")).then(on_chart_render_complete());
  chart_demosbyterritory_regular.render(document.getElementById("chart_demosbyterritory_regular")).then(on_chart_render_complete());
  r_chart15.render(document.getElementById("r_field_visits")).then(on_chart_render_complete());
  r_chart16.render(document.getElementById("r_harvest_visits")).then(on_chart_render_complete());

  /* Filtering charts in the beginning itself */
  // setTimeout(function() {
  //   render_filteredCharts_regular(true);
  // }, 1000);

  document.getElementById("mySelect1").addEventListener("change", async (e) => {
    $('#territory_name1').val('');
    render_filteredCharts_regular();
  });
  document.getElementById("territory_name1").addEventListener("change", async (e) => {
    render_filteredCharts_regular();
  });
  document.getElementById("createdDate1").addEventListener("change", async (e) => {
    render_filteredCharts_regular();
  });
  document.getElementById("endDate1").addEventListener("change", async (e) => {
    render_filteredCharts_regular();
  });
  document.getElementById("product_name1").addEventListener("change", async (e) => {
    render_filteredCharts_regular();
  });
  document.getElementById("crop_name1").addEventListener("change", async (e) => {
    render_filteredCharts_regular();
  });
}

function render_filteredCharts_regular(all) {
  chartas.setFilter(getFiltersData_RegularTab(all));
  charts1.setFilter(getFiltersData_RegularTab(all));
  chartP1.setFilter(getFiltersData_RegularTab(all));
  chartRGL1.setFilter(getFiltersData_RegularTab(all));
  chartFarmerType.setFilter(getFiltersData_RegularTab(all));
  chartFarmerType2.setFilter(getFiltersData_RegularTab(all));
  chartSeason1.setFilter(getFiltersData_RegularTab(all));
  chartS1.setFilter(getFiltersData_RegularTab(all));
  chartAP1.setFilter(getFiltersData_RegularTab(all));
  chartDC1.setFilter(getFiltersData_RegularTab(all));
  chartCS1.setFilter(getFiltersData_RegularTab(all));
  chartTP1.setFilter(getFiltersData_RegularTab(all));
  chart_reg_tble.setFilter(getFiltersData_RegularTab(all));
  chart_demosbyterritory_regular.setFilter(getFiltersData_RegularTab(all));
  r_chart15.setFilter(getFiltersData_RegularTab(all));
  r_chart16.setFilter(getFiltersData_RegularTab(all));
}


renderCharts_portfolio().catch((e) => window.alert(e.message));

renderCharts_regular().catch((e) => window.alert(e.message));




/* Filters Functionality Script */

function getFiltersData_PortfolioTab(all) {
  // all = (all == '' || typeof all == 'undefined' || all == null)? 0 : all;
  // if(all) {
  //   return {"State":{"$in": User_States_arr}};
  // }
  var states_arr = $('#mySelect').val();
  var territory = $('#territory_name').val();
  var cDate = $('#createdDate').val();
  var eDate = $('#endDate').val();
  var products_arr = $('#product_name').val();
  var crop_name = $('#crop_name').val();

  var filter_req = {};
  
  if (states_arr.length > 0) {
    // $.each(states_arr, function(i,v) {
    //   states_arr[i] = '/^' + v + '/i';
    // });
    // filter_req['State'] = { "$in": {"$regex": states_arr} };
    filter_req['State'] = { "$in": states_arr };
  } else {
    if (User_Role != "Admin") {
      filter_req['State'] = { "$in": User_States_arr };
    }
  }
  
  if (territory.length > 0) {
    filter_req['territory_name'] = { "$in": convert_array_to_RegexArray(territory) };
  } else {
    if(($.inArray(User_Role, non_Admin_arr) != -1)) {
      filter_req['territory_name'] = {"$in": convert_array_to_RegexArray(User_Territories) };
    }
  }

  if (products_arr.length > 0) {
    filter_req['Demo_Product_Name'] = { "$in": products_arr };
  }
  if (isValidString(crop_name)) {
    filter_req['Name_of_Crop'] = crop_name;
  }

  if (isValidString(cDate) && isValidString(eDate)) {
    filter_req['createdDate'] = { 
      "$gte": dateToISO(cDate), 
      "$lte": dateToISO(eDate + ' 23:59:59')
    };
  } else if (isValidString(cDate)) {
    filter_req['createdDate'] = { "$gte": dateToISO(cDate) };
  } else if (isValidString(eDate)) {
    filter_req['createdDate'] = { "$lte": dateToISO(eDate + ' 23:59:59') };
  }

  return filter_req;
}

function getFiltersData_RegularTab(all) {
  // all = (all == '' || typeof all == 'undefined' || all == null)? 0 : all;
  // if(all) {
  //   return {"State":{"$in": User_States_arr}};
  // }
  var states_arr = $('#mySelect1').val();
  var territory = $('#territory_name1').val();
  var cDate = $('#createdDate1').val();
  var eDate = $('#endDate1').val();
  var products_arr = $('#product_name1').val();
  var crop_name = $('#crop_name1').val();

  var filter_req = {};
  
  if (states_arr.length > 0) {
    // $.each(states_arr, function(i,v) {
    //   states_arr[i] = '/^' + v + '/i';
    // });
    // filter_req['State'] = { "$in": {"$regex": states_arr} };
    filter_req['State'] = { "$in": states_arr };
  } else {
    if (User_Role != "Admin") {
      filter_req['State'] = { "$in": User_States_arr };
    }
  }
  
  if (territory.length > 0) {
    filter_req['territory_name'] = { "$in": convert_array_to_RegexArray(territory) };
  } else {
    if(($.inArray(User_Role, non_Admin_arr) != -1)) {
      filter_req['territory_name'] = {"$in": convert_array_to_RegexArray(User_Territories)};
    }
  }

  if (products_arr.length > 0) {
    filter_req['Demo_Product_Name'] = { "$in": products_arr };
  }
  if (isValidString(crop_name)) {
    filter_req['Name_of_Crop'] = crop_name;
  }
  
  if (isValidString(cDate) && isValidString(eDate)) {
    filter_req['createdDate'] = { 
      "$gte": dateToISO(cDate), 
      "$lte": dateToISO(eDate + ' 23:59:59')
    };
  } else if (isValidString(cDate)) {
    filter_req['createdDate'] = { "$gte": dateToISO(cDate) };
  } else if (isValidString(eDate)) {
    filter_req['createdDate'] = { "$lte": dateToISO(eDate + ' 23:59:59') };
  }

  return filter_req;
}

function dateToISO(date, time) {
  // if (time == '' || typeof time == 'undefined' || time == null) {
  //   time = '';
  // } else {
  //   time = ' ' + time;
  // }
  const d = new Date(date);
  return d;
}



/* Filter Related Script */


$(function() {
  
  get_PortfolioTab_products();
  get_RegularTab_products();

  get_PortfolioTab_crops();
  get_RegularTab_crops();

  $('.portfolio_tab .filter_search .clear_search').on('click', function() {
    const states_empty = ($('.portfolio_tab .multi_select').val()).length == 0;

    $('.portfolio_tab .multi_select').selectpicker('deselectAll');
    $(this).parents('.filter_search').find('input').val('');
    $(this).parents('.filter_search').find('select').val('');

    // if (states_empty) {
    //   document.getElementById("territory_name").dispatchEvent(new Event("change"));
    // }
    setTimeout(function() {
      document.getElementById("territory_name").dispatchEvent(new Event("change"));
    }, 500);
  });

  $('.regular_tab .filter_search .clear_search').on('click', function() {
    const states_empty = ($('.regular_tab .multi_select').val()).length == 0;

    $('.regular_tab .multi_select').selectpicker('deselectAll');
    $(this).parents('.filter_search').find('input').val('');
    $(this).parents('.filter_search').find('select').val('');

    // if (states_empty) {
    //   document.getElementById("territory_name1").dispatchEvent(new Event("change"));
    // }
    setTimeout(function() {
      document.getElementById("territory_name1").dispatchEvent(new Event("change"));
    }, 500);
  });

  $('#mySelect').on('change', function() {
    var arr = $(this).val();
    if (User_Role == "Admin") {
      /* For Admin, It directly triggers Territories Dropdown */
      get_PortfolioTab_territories(arr);

    } else {
      fill_DrpDwn_subUsers(get_subUsers_List(arr), '#sub_user');
    }
  });
  
  $('#mySelect1').on('change', function() {
    var arr = $(this).val();
    if (User_Role == "Admin") {
      /* For Admin, It directly triggers Territories Dropdown */
      get_RegularTab_territories(arr);

    } else {
      fill_DrpDwn_subUsers(get_subUsers_List(arr), '#sub_user1');
    }
  });

  
  $('#sub_user').on('change', function() {
    var t_data = get_Territories_List($(this).val());
    fill_Portfolio_DrpDwn_Territories(t_data);
  });
  
  $('#sub_user1').on('change', function() {
    var t_data = get_Territories_List($(this).val());
    fill_Regular_DrpDwn_Territories(t_data);
  });

});


function fill_DrpDwn_subUsers(data, id) {
  var html = '<option value="">Select User</option>';
  $.each(data, function(i, v) {
    if(isValidString(v)) {
      html += '<option value="'+ i +'">'+ v + ' ('+ i +')' +'</option>';
    }
  });

  $(id).html(html);
}


/*  Portfolio Tab -- Drop Downs */
function get_PortfolioTab_territories(states_arr) {
  $.ajax({
    type: 'POST',
    url: 'https://7x6xwprtec.execute-api.ap-south-1.amazonaws.com/dev/api/commonapis/Portfolio',
    data: JSON.stringify({"states" : states_arr}),
    contentType: "application/json",
    dataType: 'json',
    // headers: {
    //   // 'Access-Control-Allow-Origin': '*'
    // },
    success: function(result) {
      fill_Portfolio_DrpDwn_Territories(result.data);
    }
  });
}
function get_PortfolioTab_products() {
  $.ajax({
    type: 'GET',
    url: 'https://7x6xwprtec.execute-api.ap-south-1.amazonaws.com/dev/api/commonapis/Portfolio/products',
    contentType: "application/json",
    dataType: 'json',
    success: function(result) {
      fill_Portfolio_DrpDwn_Products(result.data);
    }
  });
}
function get_PortfolioTab_crops() {
  $.ajax({
    type: 'GET',
    url: 'https://7x6xwprtec.execute-api.ap-south-1.amazonaws.com/dev/api/commonapis/Portfolio/crops',
    contentType: "application/json",
    dataType: 'json',
    success: function(result) {
      fill_Portfolio_DrpDwn_Crops(result.data);
    }
  });
}

function fill_Portfolio_DrpDwn_Territories(data) {
  // var html = '<option value="">Select Territory</option>';
  var html = '';
  $.each(data, function(i, v) {
    if(isValidString(v)) {
      html += '<option value="'+ v +'">'+ v +'</option>';
    }
  });

  $('#territory_name').html(html);
  $('#territory_name').selectpicker('refresh').selectpicker('selectAll');
}
function fill_Portfolio_DrpDwn_Products(data) {
  // var html = '<option value="">Select Product</option>';
  var html = '';
  $.each(data, function(i, v) {
    if(isValidString(v)) {
      html += '<option value="'+ v +'">'+ v +'</option>';
    }
  });

  $('#product_name').html(html);
  $('#product_name').selectpicker('refresh').selectpicker('deselectAll');
}
function fill_Portfolio_DrpDwn_Crops(data) {
  var html = '<option value="">Select Crop</option>';
  $.each(data, function(i, v) {
    if(isValidString(v)) {
      html += '<option value="'+ v +'">'+ v +'</option>';
    }
  });

  $('#crop_name').html(html);
}



/*  Regular Tab -- Drop Downs */
function get_RegularTab_territories(states_arr) {
  $.ajax({
    type: 'POST',
    url: 'https://7x6xwprtec.execute-api.ap-south-1.amazonaws.com/dev/api/commonapis/Regular',
    data: JSON.stringify({"states" : states_arr}),
    contentType: "application/json",
    dataType: 'json',
    // headers: {
    //   // 'Access-Control-Allow-Origin': '*'
    // },
    success: function(result) {
      fill_Regular_DrpDwn_Territories(result.data);
    }
  });
}
function get_RegularTab_products() {
  $.ajax({
    type: 'GET',
    url: 'https://7x6xwprtec.execute-api.ap-south-1.amazonaws.com/dev/api/commonapis/Regular/products',
    contentType: "application/json",
    dataType: 'json',
    success: function(result) {
      fill_Regular_DrpDwn_Products(result.data);
    }
  });
}
function get_RegularTab_crops() {
  $.ajax({
    type: 'GET',
    url: 'https://7x6xwprtec.execute-api.ap-south-1.amazonaws.com/dev/api/commonapis/Regular/crops',
    contentType: "application/json",
    dataType: 'json',
    success: function(result) {
      fill_Regular_DrpDwn_Crops(result.data);
    }
  });
}

function fill_Regular_DrpDwn_Territories(data) {
  // var html = '<option value="">Select Territory</option>';
  var html = '';
  $.each(data, function(i, v) {
    if(isValidString(v)) {
      html += '<option value="'+ v +'">'+ v +'</option>';
    }
  });

  $('#territory_name1').html(html);
  $('#territory_name1').selectpicker('refresh').selectpicker('selectAll');
}
function fill_Regular_DrpDwn_Products(data) {
  // var html = '<option value="">Select Product</option>';
  var html = '';
  $.each(data, function(i, v) {
    if(isValidString(v)) {
      html += '<option value="'+ v +'">'+ v +'</option>';
    }
  });

  $('#product_name1').html(html);
  $('#product_name1').selectpicker('refresh').selectpicker('deselectAll');
}
function fill_Regular_DrpDwn_Crops(data) {
  var html = '<option value="">Select Crop</option>';
  $.each(data, function(i, v) {
    if(isValidString(v)) {
      html += '<option value="'+ v +'">'+ v +'</option>';
    }
  });

  $('#crop_name1').html(html);
}









/* Other PAGE Script */


$(function() {
  // $.fn.selectpicker.Constructor.BootstrapVersion = '5';
  $.fn.selectpicker.Constructor.DEFAULTS.actionsBox = true;

  $('.multi_select').selectpicker();

  if(User_States_arr.length == 1) {
    $('#mySelect').selectpicker('selectAll');
    $('#mySelect1').selectpicker('selectAll');
  }



  const services_domain = 'https://7x6xwprtec.execute-api.ap-south-1.amazonaws.com';
  const dwnld_links = [
    {
      id: '.btn_portfolio',
      link: services_domain + '/dev/api/sampletracking/download/Portfolio'
    },
    {
      id: '.btn_regular',
      link: services_domain + '/dev/api/sampletracking/download/Regular'
    },
    {
      id: '.btn_commercial',
      link: services_domain + '/dev/api/sampletracking/download/Commercial'
    },
  ];

  setFiltered_DwnldLink(dwnld_links[0]);
  setFiltered_DwnldLink(dwnld_links[1]);
  setFiltered_DwnldLink(dwnld_links[2]);

  $('.download_links_container').removeClass('d-none');

});

function setFiltered_DwnldLink(dwnld_link_obj) {
  var url = new URL(dwnld_link_obj.link);
  var search_params = url.searchParams;

  /* Adding parameters */
  if (User_Role != "Admin") {
    if(User_States_arr.length >0 && User_States_arr != '') {
      search_params.set('states', User_States_arr.join(','));
    }
  }
  if(($.inArray(User_Role, non_Admin_arr) != -1)) {
    if(User_Territories.length >0) {
      search_params.set('territories', User_Territories.join(','));
    } else {
      search_params.set('territories', 'invalid');
    }
  }
  
  url.search = search_params.toString();

  var new_url = url.toString();

  $(dwnld_link_obj.id).attr('href', new_url);
  $(dwnld_link_obj.id).attr('target', '_blank');
}




/* PRINT Functionality */

var meta_title = '';

$(function() {
  setTimeout(function() {
    $('.btn_print').removeClass('hidden_4time');
    $('.btn_print_info').addClass('d-none');
  }, 10000);

});

// $(window).scroll(function (event) {
//   var scroll = $(window).scrollTop();
//   if(scroll > 4000) {
//     $('.btn_print').removeClass('hidden_4scroll');
//   }
// });

// function PrintDiv() {  
//   // var divContents = document.getElementById("printdivcontent").innerHTML;
//   var divContents = document.getElementById("nav-home").innerHTML;  
//   var headContents = document.getElementsByTagName('head')[0].innerHTML;  

//   var printWindow = window.open('', '', 'height=700,width=900');  
//   printWindow.document.write('<html><head><title>Print DIV Content</title>');
//   printWindow.document.write(headContents);
//   printWindow.document.write('</head><body >');
//   printWindow.document.write(divContents);
//   printWindow.document.write('</body></html>');  
    
//   setTimeout(function() {
//     printWindow.document.close();
//     printWindow.print();
//   }, 10000);
  
// }

function scrollDown(ctr) {
  window.scrollBy(0, window.innerHeight);
  setTimeout(function(){
    // console.log(ctr);
    if(ctr == 0) {
      window.print();

      $('.flitered_content').removeClass('print_resize');
      $('.no_print').removeClass('d-none');
      $('.tab-content').removeClass('print_in_process');
      $('title').text(meta_title);
    } else {
      ctr--;
      scrollDown(ctr)
    }
  }, 500);
}

function getPageHeight() {
  var body = document.body,
  html = document.documentElement;

  var height = Math.max( body.scrollHeight, body.offsetHeight, 
                   html.clientHeight, html.scrollHeight, html.offsetHeight );
  return height;
}

$('.btn_print').on('click', function() {
  console.log('Window Length'+ getPageHeight());
  // var parent_id = $(this).parents('.tab-pane');
  meta_title = $('title').text();
  $('title').text($(this).data('title'));

  $('.flitered_content').addClass('print_resize');
  $('.no_print').addClass('d-none');
  $('.tab-content').addClass('print_in_process');

  var pageHt = getPageHeight();
  var window_ht = window.innerHeight
  var total_pages = Math.floor(pageHt / window_ht);

  // console.log('Window Length'+ pageHt);
  // console.log('Window Height'+ window_ht);
  // console.log('Total Pages'+ total_pages);

  scrollDown(total_pages);
  
  window.onbeforeprint = (event) => {
    
  }
  window.onafterprint = (event) => {
    
  }

  // PrintDiv();

  // $('#print_content').printThis({
  //     header: "<h1>Portfolio Charts</h1>",
  //     printDelay: 10000,
  //     beforePrint: null
  // });

});



/** Helper Funtions */

function convert_array_to_RegexArray(array) {
  // let regex_array = [];
  // for (let i = 0; i < array.length; i++) {
  //   regex_array.push( new RegExp(array[i], "i") );
  // }
  return array;
  // return regex_array;
}